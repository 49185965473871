.blogPageContainer {
  padding-top: 87px;
}
.pageHeader {
  font-size: 1.75rem;
  font-weight: 500;
  margin-bottom: 10px;
}
.blogsArchivesContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-bottom: 50px;
  padding-top: 10px;
}
.paginationContainer {
  display: flex;
  justify-content: flex-end;
  width: 100% !important;
}

@media only screen and (max-width: 767px) {
  .blogCard {
    width: 100%;
  }
  .requestBlueSection {
    justify-content: center;
    text-align: center;
  }
  .requestBlueSection > div {
    width: 100%;
    margin-bottom: 20px;
  }
  .paginationContainer {
    display: flex;
    justify-content: center;
  }
}