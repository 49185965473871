.navbar {
  backdrop-filter: blur(26px);
  background: #000000a1;
}

.navbar-nav .nav-link {
  color: white;
}

.navbar > div:first-child {
  max-width: 1320px;
  overflow: hidden;
}

.navbar-collapse > ul {
  width: 900%;
  overflow: hidden;
}
.container-fluid.anime-nav .nav-item:last-child {
  transform: translateX(148px);
}
.container-fluid.anime-nav .nav-item {
  transform: translateX(-144px);
}
.anime-nav.container-fluid .navbar-brand img {
  clip-path: polygon(0 0, 26% 0, 26% 100%, 0% 100%);
}
.container-fluid .navbar-brand img {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}
.container-fluid .navbar-brand img, .container-fluid .nav-item {
  transition: 700ms all ease;
}
.navbar-collapse > ul > li {
  margin-left: 2%;
}

.btn-outline-custom {
  color: white;
  border: 1px solid white;
}

.btn-outline-custom:hover {
  background: #ef6d58;
  border: 1px solid #ef6d58;
}

.nav-item:last-child > a {
  border: 3px solid transparent;
  border-radius: 9px;
  background: white;
  color: black;
}

.nav-item:last-child > a:hover {
  border-color: white;
  color: white;
  background: transparent;
}

@media only screen and (max-width: 992px) {
  .navbar-collapse > ul {
    /* margin-left: 15%; */
  }

  .navbar-collapse > ul > li {
    /* margin-left: 20%; */
  }
  .navbar-container.container {
    max-width: 800px;
  }
}

@media only screen and (max-width: 878px) {
  .navbar-collapse > ul {
    /* margin-left: 10%; */
  }

  .navbar-collapse > ul > li {
    /* margin-left: 20%; */
  }
  .nav-link {
    font-size: small;
  }
}

@media only screen and (max-width: 767px) {
  .navbar > div:first-child {
  }

  .navbar-collapse > ul {
    margin-left: 0;
    width: 100%;
  }

  .navbar-collapse > ul > li {
    margin-left: 0;
    text-align: center;
  }
}

.loginBtn {
  width: 100px;
  text-align: center;
}
