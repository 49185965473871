
.homeAgentTestimonialSectionCard {
  border: 1px solid #E6E6E6;
  margin-right: 20px;
  white-space: normal;
  display: inline-block;
  background: white;
  border-radius: 24px;
  padding: 30px;
  padding-bottom: 0;
  width: 554px;
}

.homeAgentTestnomialAvatar {
  width: 54px;
}
.testimonialContent, .homeAgentTestimonialMemberName, .homeAgentTestimonialMemberCompany {
  color: #16002C;
}
.btnContainer {}
.testimonialBtn {
  background: #f0f0f0;
  margin-left: 19%;
  margin-bottom: 20px;
}
.testimonialBtn:hover {
  background: #d8d8d8;
}
@media only screen and (max-width: 767px) {
  .homeAgentTestimonialSectionCard {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .homeAgentTestnomialAvatar {
    width: 50px;
    height: 50px;
  }
  .homeAgentTestimonialMemberName {
    font-size: medium !important;
  }
  .homeAgentTestimonialMemberCompany {
    font-size: small !important;
  }
}
