.blogCard {
  width: calc((100% - 59px)/3);
  margin-right: 11px;
  margin-bottom: 22px;
  border-radius: 8px;
  padding-bottom: 14px;
  box-shadow: 0 0 1px 0 rgb(0 0 0 / 4%), 0 0 2px 0 rgb(0 0 0 / 6%), 0 4px 8px 0 rgb(0 0 0 / 4%);
  transition: 300ms ease-in-out;
  cursor: pointer;
}
.blogCard:hover {
  background: #f4f9ff;
  box-shadow: 2px 4px 10px #8686862b;
  transform: scale(1.01);
}
.blogCardImgContainer{
  height: 250px;
}
.blogCardImg {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.blogCardDetailsContainer {
  padding: 20px;
}
.blogCardDetailsHeader {
  line-height: 1.6;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
}
.blogCardText {}

@media only screen and (max-width: 767px) {
  .blogCard {
    width: 100%;
  }
}