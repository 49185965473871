.requestBlueSection {
  box-shadow: 0 4px 54px 0 rgb(66 74 91 / 10%);
  background-color: #101dfa;
  justify-content: space-between;
  border-radius: 16px;
  align-items: center;flex-wrap: wrap;
  margin: 38px 0 109px;
  padding: 48px 72px;
  display: flex;
}
.requestBlueSection > div {
  width: 45%;
  color: white;
  font-size: 1.75rem;
  font-weight: 500;
}
@media only screen and (max-width: 767px) {
  .requestBlueSection {
    justify-content: center;
    text-align: center;
  }
  .requestBlueSection > div {
    width: 100%;
    margin-bottom: 20px;
  }
}