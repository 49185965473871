.pageContainer {
  padding-top: 80px;
  padding-bottom: 70px;
}
.illustration {
  width: 359px;
  height: 311px;
  margin: 0 auto;
  display: block;
}
.btn {
  width: fit-content;
  border-radius: 9px;
  background-color: #3a6fe6;
  color: white;
  margin: 0 auto;
  padding: 10px 50px;
  cursor: pointer;
  text-decoration: none;
  display: block;
  transition: 200ms transform ease;
}
.btn:hover {
  transform: scale(1.09);
  color: white;
}
.illustrationLink {
  margin-top: 50px;
  text-align: center;
  font-size: small;
}