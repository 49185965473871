
.homeAgencyFirstSection {
  background: #121313;
  color: #ffffff99;
  padding-top: 60px !important;
  padding-bottom: 130px;
}
.homeAgencyFirstSectionContent {
}
.homeAgencyFirstSectionHeading {
  font-size: 4.4em;
  line-height: 1.3;
  font-weight: 900;
  letter-spacing: 3.08px;
  text-align: left;
  margin-top: 35px !important;
}
.linedSectionContainer {
  position: relative;
}
.firstLinedImg {
  position: absolute;
  bottom: -17px;
  width: 100%;
  left: 0;
  stroke-dashoffset: 592;
}
.firstLinedImg:global(.show), .foundersLinedImg:global(.show) {
  stroke-dasharray: 592;
  stroke-dashoffset: 592;
  animation: firstLineAnimation 1s 200ms ease-in-out 1;
  animation-fill-mode: forwards;
}
@keyframes firstLineAnimation {
  0% {stroke-dashoffset: 592;}
  100% {stroke-dashoffset: 0;}
}
.foundersLinedImg {
  position: absolute;
  width: 95%;
  bottom: -13px;
  left: 4px;
}
.laserFocusLine {
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
}
.aiScreeningLine {
  position: absolute;
  bottom: -12px;
  left: 0;
  width: 100%;
  stroke-dashoffset: 272;
}
.aiScreeningLine:global(.show) {
  stroke-dasharray: 272;
  animation: aiUnderlineAnime 1s ease-in-out 1;
  animation-fill-mode: forwards;
}
@keyframes aiUnderlineAnime {
  0% {
    stroke-dashoffset: 272;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
.findUnderline {
  position: absolute;
  bottom: -12px;
  left: 0;
  width: 100%;
  stroke-dasharray: 272;
  stroke-dashoffset: 272;
}
.findUnderline:global(.show) {
  stroke-dasharray: 272;
  animation: findUnderlineAnime 1s ease-in-out 1;
  animation-fill-mode: forwards;
}
@keyframes findUnderlineAnime {
  0% {
    stroke-dashoffset: 272;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
.firstAboutSectionHeadUnderline {
  position: absolute;
  width: 100%;
  bottom: -5px;
  left: 0;
}
.laserFocusLine:global(.show), .firstAboutSectionHeadUnderline:global(.show) {
  animation: smallUnderlineAnime 1s ease-in-out 1;
  animation-fill-mode: forwards;
  stroke-dasharray: 170;
  stroke-dashoffset: 170;
}
@keyframes smallUnderlineAnime {
  0% {stroke-dashoffset: 170;}
  100% {stroke-dashoffset: 0;}
}
.homeAgencyFirstSectionSummary {
  font-size: 1.3em;
  font-weight: 300;
  width: 57%;
  text-align: center;
  margin: 60px auto;
  color: #ffffffb3;
}
.linkText {
  color: white;
}
.linkText:hover {
  color: white;
}
.homeAgencyFirstSectionSummaryHighlight {
  font-weight: 700;
}
.homeAgencyTagsSectionContainer {
  font-size: 25px;
  text-align: center;
}
.textBgCustom{
  font-size: 0.6em !important;
}
.roundedPillCustom {
  margin: 0 14px 0 0;
  padding: 8px 39px 8px 37px;
  border-radius: 25px;
  border: solid 3px rgba(255, 255, 255, 0.11);
}
.homeAgencyFirstSectionSmallText {
  color: white;
  font-size: small;
  text-align: center;
}
.homeAgencyFirstSectionIllustration {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 41%;
  bottom: 0;
  height: 100%;
}
.firstSectionBtnContainer {
  text-align: center;
  margin-top: 60px;
}
.text-theme {
  color: #ef6d58;
}
.btnTheme {
  background-color: #fff;
  color: black;
  font-size: 1em;
  border-radius: 9px;
  padding: 9px 82px 10px 82px;
}
.btnTheme:hover {
  background-color: transparent;
  border: 1px solid white;
  color: white;
}
.btn-pricing-theme {
  box-shadow: 1px 1px 9px 0 #d8d8d8;
}
.border-bottom-theme {
  border-bottom: 1px solid #f3d1bf;
}
.homeAgencyFirstSection, .homeAgencyClientsSection, .callToActionSectionContainer, .homeAgencyTestimonialContainer {
  color: white;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 120px 0;
}
.callToActionSectionContainer {
  padding: 300px 0 88px 0;
  margin-top: -109px;
}
.homeAgencyClientsSectionBox {
  margin-top: 50px;
}
.homeAgencyClientsSectionHeading, .homeAgencyBackedByCardContent {
  font-size: inherit;
  font-weight: 600;
}
.homeAgencyClientsSection {
  margin-top: -130px !important;
}
.homeAgencyClientsSectionHeading {
  text-align: center;
  font-size: 1.4em;
  font-weight: 400;
}
.homeAgencyClientsSectionContent {
  gap: 48px;
}
.homeAgencyClientsCard {
  width: calc(100%/8);
}
.homeAgencyClientsLogo {
  margin: 0 auto;
  display: block;
}
.homeAgencyClientsName {
  font-size: 0.88em;
  text-align: center;
  margin-top: 11px;
  margin-bottom: 5px;
  color: #ffffff99;
}
.homeAgencyClientsPercentage {
  text-align: center;
  font-size: initial;
}
.homeAgencySecondHeadingSection {
  margin-top: 160px;
}
.homeAgencyBackedBySection {
  margin-top: 79px;
}
.homeAgencyBackedByHeading {
  width: fit-content;
  display: inline;
}
.homeAgencyBackedBySectionBox {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-direction: column;
}
.homeAgencyBackedByContentBox {
  width: 86%;
  gap: 2%;
  justify-content: center;
}
.homeAgencyBackedByCard {
  margin-left: 10px;
  width: 25%;
}
.homeAgencyBackedByAvatar {
}
.homeAgencyBackedByCardContent {
  font-size: 13px;
  line-height: 1.3;
}
.homeAgentAboutSection {
  margin-top: 100px;
}
.homeAgentAboutSectionBox {
}
.homeAgentAboutSectionIllustrationImage {
  margin: 0 auto;
  display: block;
  width: 100%;
}
.homeAgentSectionIllustrationContainer2 {
  padding-top: 92px;
}
.homeAgentSectionIllustrationContainer3 {
  padding-top: 60px;
}
.homeAgentAboutSectionMainHeading {
  line-height: 1.1;
  margin-top: 55px;
}
.homeAgentAboutSectionSubheading {
}
.homeAgentAboutSectionIllustrationContainer{
}

.home-agent-help-section-logos {
  width: 70px;
  height: 70px;
}
.homeAgencySecondHeadingSection {
}
.homeAgentAboutSectionEncapsulator, .homeAgentAboutSectionEncapsulator2 {
  margin-top: 160px;
}
.homeAgencyTestimonialContainer {
  padding-bottom: 229px;
  margin-bottom: -165px;
  z-index: 1;
  position: relative;
}
.homeAgentTesimonialCardsContainer, .homeAgentTesimonialCardsContainer2 {
  white-space: nowrap;
  padding-bottom: 20px;
  display: flex;
}
.testimonialSectionCard {
  margin: 0 auto !important;
}
.homeAgentTesimonialCardsContainer {
  /*animation: animeGoRight 60s infinite linear alternate;*/
}
@keyframes animeGoRight {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-450%);
  }
}
.home-agent-testimonial-section-card {
  border: 1px solid #E6E6E6;
  margin-right: 20px;
  white-space: normal;
  display: inline-block;
  background: white;
  border-radius: 24px;
  padding: 30px;
  width: 350px;
}
.home-agent-testnomial-avatar {
  width: 54px;
}
.home-agent-testimonial-section-stars {
}
.home-agent-testinomial-card-member-details {
}
.home-agent-testimonial-member-detail-box {
}
.home-agent-testimonial-member-company {
  color: #D0D0D0;
}
.home-agent-testimonial-linkedin-logo {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  cursor: pointer;
}

/*.home-agent-pricing-extra-pill-container {*/
/*  height: 25px;*/
/*}*/
/*.home-agent-pricing-pill {*/
/*  background: #ef6d58;*/
/*}*/
/*.home-agent-pricing-cards {*/
/*  cursor: pointer;*/
/*  border: 1px solid #f3d1bf;*/
/*  transition: 0.3s all;*/
/*  padding: 40px 12px 40px 40px;*/
/*  background: white;*/
/*}*/
/*.home-agent-pricing-cards:hover {*/
/*  box-shadow: 1px 1px 9px 0 #d8d8d8;*/
/*  transform: scaleY(1.1) translateY(-20px);*/
/*}*/
/*.home-agent-pricing-section-points-list {*/
/*}*/
/*.home-agent-pricing-cards:hover > .btn-pricing-theme {*/
/*  background: #ef6d58;*/
/*  color: white;*/
/*}*/
/*.accordion-button:not(.collapsed) {*/
/*  color: black;*/
/*  background: white;*/
/*  border: none;*/
/*}*/
/*.accordion-item {*/
/*  border: 1px solid #f3d1bf !important;*/
/*}*/
/*.accordion-item:first-child {*/
/*}*/
/*.accordion-item:last-child {*/
/*}*/
/*.accordion-button:focus {*/
/*  box-shadow: 0 0 0 0.25rem #f3d1bf;*/
/*}*/
/*.accordion-button::after {*/
/*  !*background-image: url("./accordionAfterImage.svg");*!*/
/*  background-image: url("data:image/svg+xml,%3Csvg id='up' xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Ccircle id='Oval' cx='16' cy='16' r='16' fill='%23ef6d58'/%3E%3Cpath id='Path' d='M5.045.341.249 5.822A1 1 0 0 0 1 7.481h9.592a1 1 0 0 0 .753-1.659L6.551.342a1 1 0 0 0-1.506 0Z' transform='translate(10.202 10.519)' fill='%23fff'/%3E%3C/svg%3E");*/
/*}*/
/*.accordion-button:not(.collapsed)::after {*/
/*  !*background-image: url("./accordionAfterImage.svg");*!*/
/*  background-image: url("data:image/svg+xml,%3Csvg id='up' xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Ccircle id='Oval' cx='16' cy='16' r='16' fill='%23ef6d58'/%3E%3Cpath id='Path' d='M5.045.341.249 5.822A1 1 0 0 0 1 7.481h9.592a1 1 0 0 0 .753-1.659L6.551.342a1 1 0 0 0-1.506 0Z' transform='translate(10.202 10.519)' fill='%23fff'/%3E%3C/svg%3E");*/
/*}*/
/*.home-agent-contact-section-logos {*/
/*  width: 30px;*/
/*}*/
/*.winning-resume-img {*/
/*  width: 100%;*/
/*  height: auto;*/
/*}*/
.homeAgencyIcebergSection {
  background: #3F7EFC;
  padding: 150px 0;
}
.homeAgencyIcebergSectionIllustration {
}
.homeAgencyIcebergHeading {
}
.homeAgencyCallToActionSectionBox {
  width: 60%;
  border-radius: 19px;
  margin-top: -92px;
  padding: 28px 0;
  color: white;
  text-align: center;
}
.homeAgencyCallToActionSectionContent {
  color: #F4F4F4b3;
  font-size: small;
}
.homeAgencyIcebergHeadingWithoutBold {}
.homeAgencyIcebergTextContent {
  color: #E6E6E6;
}
.homeAgencyBackedUpSectionLogo {
  position: relative;
  top: -10px;
}
@media only screen and (max-width: 1170px) {
  .homeAgencyFirstSectionHeading {
    font-size: 5vw;
  }
  .homeAgentAboutSectionMainHeading {

  }
}
@media only screen and (max-width: 1081px) {
  .homeAgencyFirstSection {
  }
  .homeAgencyClientsSectionBox {
    width: 95%;
  }
  .homeAgencyBackedBySectionBox {
    flex-direction: column;
  }

  .homeAgencyBackedByContentBox {
    flex-wrap: wrap;
    justify-content: center;
  }
  .homeAgencyBackedByCard {
    width: 59%;
    margin: 20px;
  }
}
@media only screen and (max-width: 878px) {
.homeAgencyClientsSectionContent {
  gap: 42px;
}
}
@media only screen and (max-width: 767px) {
  .homeAgencyFirstSection {
    height: auto;
    padding-top: 57px;
  }
  .homeAgencyFirstSectionHeading {
    font-size: xx-large;
  }
  .homeAgentAboutSection {
    margin-top: 50px;
    padding-bottom: 50px;
  }
  .home-agent-testnomial-avatar {
    width: 40px;
  }
  .home-agent-testimonial-member-name {
    font-size: medium !important;
  }
  .home-agent-testimonial-member-company {
    font-size: small !important;
  }
  .homeAgencyFirstSectionIllustration,
  .homeAgentAboutSectionIllustrationImage {

  }
  .homeAgencyFirstSectionContent {
  }
  .homeAgencyBackedUpSectionLogo {
    width: 100px;
    height: 80px;
  }
  .homeAgencyClientsSectionContent {
    flex-wrap: wrap;
    justify-content: space-around !important;
    gap: 5%;
  }
  .homeAgencyClientsSectionBox {
    width: 100%;
    border-radius: 0;
    margin-top: 0;
  }
  .homeAgencyClientsCard {
    width: 21%;
  }

.homeAgencyClientsSection, .callToActionSectionContainer, .homeAgencyTestimonialContainer {
    background-image: none !important;
    background-color: black;
  }
  .homeAgencyClientsCard:not(:nth-child(1n+5)) {
    margin-bottom: 25px;
  }
  .homeAgencyClientsLogo {
  }
  .homeAgencyClientsName {
  }
  .homeAgencyBackedBySectionBox {
    display: flex;
    flex-direction: column;
  }
  .homeAgencyBackedByCard {
    margin-bottom: 10px;
  }
  .homeAgencyBackedByCard:last-child {
    margin-top: 20px;
  }
  .homeAgencyIcebergSectionIllustration {
  }
  .homeAgencyCallToActionSectionBox {
    width: 100%;
    border-radius: 0;
  }
  .homeAgentAboutSectionEncapsulator, .homeAgentAboutSectionEncapsulator2 {
    display: grid;
    grid-template-columns: 100%;
    margin-top: -46px;
  }
  .homeAgentAboutSectionEncapsulator > div:nth-child(2) {
    grid-row-start: 1
  }
  .homeAgentAboutSectionBox {
  }
  .homeAgentAboutSectionIllustrationContainer{
    margin-top: 70px;
  }
  .home-agent-contact-section-text {
    font-size: x-small !important;
  }
  .homeAgencyClientsCardsContainer {
  }
  .home-agent-testimonial-section-card {
    width: 90%;
  }
  .home-agent-testnomial-avatar {
    width: 50px;
    height: 50px;
  }
  .homeAgencyBackedByContentBox {
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
  }
  .ourClientsHeading {
    margin-top: 50px;
    margin-bottom: -33px;
  }
  .firstLinedImg {
    bottom: -15px;
  }
  .homeAgencyIcebergHeading {
    margin-top: 50px;
  }
  .callToActionSectionContainer {
    padding-top: 159px;
    padding-bottom: 18px;
  }
  .homeAgencyIcebergSection {
    padding-bottom: 186px;
    padding-top: 200px;
  }
  .homeAgencyTestimonialContainer {
    padding-bottom: 112px;
    padding-top: 0px !important;
  }
  .homeAgencyClientsSection {
    margin-top: -37px !important;
  }
  .callToActionSectionContainer {
    padding-top: 140px;
    padding-bottom: 41px;
  }
  .homeAgencyClientsSection {
    padding-top: 89px;
    padding-bottom: 79px;
  }
  .homeAgencyFirstSection {
    padding: 88px 0;
  }
}
@media only screen and (max-width: 380px){
  .homeAgencyBackedByCard {
    width: 60%;
  }
}
