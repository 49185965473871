
.paginationElement {
  list-style: none;
  display: flex;
  width: fit-content;
  padding-left: 0;
}

.paginationElement li {
  padding: 10px;
  height: 47px;
  width: 47px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
}
.ellipses:hover {
  background-color: transparent !important;
}
.paginationElement li:hover {
  background-color: #bcbcbc;
}
.paginationElement li button {
  display: inline-block;
  border-radius: 50%;
  width: 47px;
  height: 47px;
  border: none;
  margin-top: -10px;
  margin-left: -10px;
}
.paginationElement li.active {
  background-color: #cfcfcf;
  color: black;

}

.paginationElement li.button{
  background-color: transparent;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor:pointer;
}

.paginationElement li.button:hover{
  background-color: white;
  color: black;
}

.paginationElement li.button:focus{
  outline: none;
}