.aboutPageContainer {
}

.aboutSectionHeading {
  color: #3046df;
  letter-spacing: 8px;
  font-weight: 800;
  text-transform: uppercase;
}

.aboutSectionSecondHeading {
  font-size: 3rem;
  font-weight: 700;
  margin-top: 15px;
  margin-bottom: 60px;
}
.ourValuesContainerInner, .investorSectionInner, .mediaCoverageInner {
  padding: 8rem 7rem;
}
.awardsContainer, .investorsSection {
  background-color: #fafafa;
}
/*// firstSection */
.firstSection {
  background-color: #000;
  color: white;
  padding-top: 184px;
  padding-bottom: 218px;
  text-align: center;
}

.firstSectionHeading {
  font-size: 4.5rem;
  line-height: 1.4;
  font-weight: 700;
}

.firstSectionSubHeading {
  font-size: 1.8rem;
  margin: 30px 0;
}

.firstSectionHeadingHighlight {
  color: #00e7ba;
}

/*// ourMission Section*/
.ourMissionSection {
  padding: 13rem 7rem 0 7rem;
  background-size: contain;
  margin-top: -102px;
  background-repeat: no-repeat;
}
.ourMissionSection .aboutSectionHeading{
  margin-top: 24px;
}
.ourMissionSection .aboutSectionSecondHeading {
  margin-top: 5px;
  margin-left: -10px;
}
.rightSideTextContainer {
  font-size: 1.3rem;
  color: #6d6d6d;
}

/* Awards Section*/
.awardsContainer {
  padding-top: 124px;
  padding-bottom: 124px;
  text-align: center;
}
.awardsContainer .aboutSectionSecondHeading {
  margin-top: 31px;
}
.awardsCardsContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.awardsSectionCards {
  width: 25%;
}

.awardsCardImg {
  width: 13rem;
  height: 13rem;
}

.awardsCardTextContent {
  font-size: 1.3rem;
  margin-top: 22px;
}

/* Our Values*/
.ourValuesContainer {
}

.valuesCardContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}

.valuesCard {
  display: flex;
  align-items: flex-start;
  width: 48%;
  justify-content: space-between;
  margin-top: 109px;
}

.valueCardImg {
  width: 5rem;
  height: 5rem;
}

.valueCardContentContainer {
  width: 79%;
}

.valueCardContentHeading {
  font-weight: 700;
  font-size: 1.6rem;
}

.valueCardContentText, .investorDetails {
  color: #6d6d6db5;
  margin-top: 1.1rem;
  font-size: 1.1rem;
}

/* Investors Section*/
.investorsSection {
}
.investorsCardContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}
.investorsCard {
  background: white;
  width: 28%;
  padding: 81px 2% 54px;
  text-align: center;
  border-radius: 0.5rem;
}
.investorName {
  font-weight: 700;
  font-size: 1.5rem;
  margin-top: 12px;
  margin-bottom: 12px;
}

.investorDetails {
}

/* Media Coverage */
.mediaCoverage {
}

.mediaCoverageCardsContainer {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-around;
}

.mediaCoverageCard {
  cursor: pointer;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 1px 1px 5px 1px #e1e1e1;
  width: 295px;
}

.mediaCoverageCardImgSection {
}

.mediaCoverageCardImg {
  border-radius: 15px;
}

.mediaCoverageCardTextSection, .mediaCoverageCardMarker {
  padding: 5%;
}
.mediaCoverageCardTextSection {
  font-size: 1.1rem;
  height: 36%;
}
.mediaCoverageCardMarker {
  font-weight: 700;
  font-size: 0.8rem;
  color: #6d6d6d;
}

/* Call to Action */
.callToAction {
  background-size: 100% 100%;
  color: black;
  text-align: center;
  padding: 114px 0;
}

.callToActionHeading {
}

.callToActionSecondHeading {
}

.callToActionBtnContainer {
  text-align: center;
}

.callToActionBtn {
  text-decoration: none;
  color: black;
  font-weight: 700;
  background-color: white;
  margin-top: 47px;
  display: inline-block;
  padding: 15px 50px;
  border-radius: 10px;
}
.callToActionBtn:hover {
  color: #000b;
}
@media screen and (max-width: 1210px) {
  .firstSectionHeading {
    font-size: 2.5rem;
  }

  .firstSectionSubHeading {
    font-size: 1.2rem;
  }

/*  our Mision section*/
  .ourMissionSection, .ourValuesContainerInner, .investorSectionInner, .mediaCoverageInner {
    padding-left: 0;
    padding-right: 0;
  }
/*  Awards Section*/
  .awardsSectionCards {
    width: 50%;
    margin-top: 52px;
  }
/*  Our values section*/
  .valueCardContentContainer {
    width: 71%;
  }
/*  investors section*/
  .investorsCard {
    width: 40%;
  }
}
@media screen and (max-width: 835px) {
  .firstSectionHeading {
    font-size: 2rem;
  }

  .firstSectionSubHeading {
    font-size: 1rem;
  }
/*  our mission*/
  .ourMissionSection, .ourValuesContainerInner, .investorSectionInner, .mediaCoverageInner {
    padding-left: 6%;
    padding-right: 6%;
  }
  .investorsCard > img {
    width: 9rem;
    height: 9rem;
  }
  .investorName {
    font-size: 1.2rem;
  }
  .investorDetails {
    font-size: 1.1rem;
  }
}

@media only screen and (max-width: 767px) {
  /*about page commons*/
  .aboutSectionHeading {
    font-size: 0.7rem;
  }
  .aboutSectionSecondHeading {
    font-size: 2rem;
  }
  /*  our mission*/
  .ourMissionSection {
    text-align: center;
    padding-top: 91px;
    padding-bottom: 111px;
    background-color: white;
  }
  .ourMissionSection .aboutSectionHeading {
  }
  .ourMissionSection .aboutSectionSecondHeading {
    margin-top: 15px;
  }
  .rightSideTextContainer {
    margin-top: 25px;
    font-size: 1rem;
  }
  /*  Awards Section*/
  .awardsCardImg {
    width: 10rem;
    height: 10rem;
  }
  .awardsSectionCards, .valuesCard {
    width: 100%;
  }
  .awardsCardTextContent {
    font-size: revert;
  }
/*  values section*/
  .valuesCard {
    flex-direction: column;
    align-items: center;
    margin-top: 72px;
  }
  .valuesCard:first-child {
    margin-top: 0;
  }
  .valueCardContentHeading {
    font-size: 1.2rem;
  }
  .valueCardContentText {
    font-size: 0.9rem;
  }
  .valueCardContentContainer {
    width: 100%;
    text-align: center;
  }
/*  media section*/
  .mediaCoverageCardsContainer {
    gap: 27px;
  }
/*  investors section*/
  .investorsCard {
    width: 70%;
  }
/*  call To Action*/
  .callToAction {
    background-color: #00e7ba;
    padding-top: 80px;
    padding-bottom: 99px;
  }
}