.homeAgencyFooterSectionHeading:not(:first-child) {
  font-size: small;
  font-weight: 700;
  opacity: 0.64;
  color: #1E1E20;
  margin-bottom: 15px;
}
.homeAgencyFooterSectionImage {
  width: 100px;
}
.homeAgencyFooterContentContainer, .homeAgencyFooterContentContainer2 {
  cursor: pointer;
  color: #1e1e206e;
  font-size: small;
  margin-top: 10px;
  display: flex;
  gap: 15px;
  align-items: flex-start;
}
.homeAgencyFooterContentContainer > a {
  color: inherit;
  text-decoration: none;
}
.homeAgencyFooterContentContainer2 {
  cursor: initial;
}
.homeAgencyFooterFlagImageContainer {
}
.homeAgencyFooterContentRightSection{
  color: black;
}
.homeAgencyFooterSectionContent {
  opacity: 0.64;
}
.homeAgencyFooterIconClass {
  border-radius: 5px;
  cursor: pointer;
}
.homeAgencyFooterCopyrightInfoSection, .homeAgencyFooterCopyrightInfoSection  a:global(.btn) {
  color: #1E1E203D !important;
  font-size: small;
  font-weight: 600;
}
@media only screen and (max-width: 767px) {
  .FooterContainer {
    padding-top: 65px;
  }
  .homeAgencyFooterSectionHeading {
    font-size: initial;
  }
  .homeAgencyFooterSectionImage {
    width: 200px;
  }
  .homeAgencyFooterContentContainer {
    font-size: initial;
    margin-bottom: 30px;
    margin-top: 30px;
  }
}