.App {
  font-family: "Raleway";
}
body > iframe {
  pointer-events: none;
}
body::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
body::-webkit-scrollbar-button {
  background:#fff;
  width: 0px;
  height: 0px;
}
body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background:#fff;
}
body::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
  background: #101dfa;
  height:10px;
}