.pageContainer {
  padding-top: 135px;
}
.breadCrumbsContainer {
  display: flex;
  gap: 5px;
  padding-left: 13px;
}
.breadCrumbsContainer a {
  color: rgba(31, 31, 31, 0.6) !important;
  text-decoration: underline !important;
}
.breadCrumbsContainer a:hover {
  text-decoration: none;
}
.breadCrumbsContainer span {
  text-decoration: none;
}
.imgBanner {
  width: 100%;
}
.imgContainer {
  border-radius: 8px;
  margin-bottom: 43px;
  overflow: hidden;
}
.lowerPageContainer {
  padding-left: 13px;
  padding-right: 13px;
}
.pageHeader {}
.headerText {
  font-size: 2rem;
  font-weight: 500;
}
.shareBtnContainer {
  display: flex;
  column-gap: 9px;
  margin: 26px auto 16px;
}
.iconContainerEncap {
  width: 26px;
  height: 26px;
  background-size: 26px;
  border-radius: 4px;
}
.facebookIcon {
  width: 26px;
  height: 26px;
  background-color: rgb(24, 119, 242);
  display: inline-block;
  border-radius: 4px;
}
.twitterIcon {
  width: 26px;
  height: 26px;
  background-color: rgb(29, 155, 240);
  display: inline-block;
  border-radius: 4px;
}
.linkedinIcon {
  width: 26px;
  height: 26px;
  background-color: rgb(0, 123, 181);
  display: inline-block;
  border-radius: 4px;
}
.blogContentContainer {
  padding-top: 16px;
  padding-bottom: 20px;
}
.relatedPostContainer {}
.sectionHeader {
  text-align: center;
  font-size: 1.75rem;
  font-weight: 500;
  margin-top: 146px;
  margin-bottom: 18px;
}
.blogCardContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-bottom: 38px;
  padding-top: 10px;
}
@media only screen and (max-width: 767px){
  .lowerPageContainer {
    padding: 0;
  }
}