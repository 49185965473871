.pageContainerWidth {
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 20px;
}
.mainBtn, a.mainBtn:visited {
  border: 3px solid transparent;
  border-radius: 9px;
  background: white;
  padding: 10px 30px;
  color: black;
  text-decoration: none;
  transition: 700ms all ease;
}

.mainBtn:hover , a.mainBtn:hover {
  border-color: white;
  color: white;
  background: transparent;
}
.loaderContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 70px;
  height: 50px;
}
.loaderContainer > div {
  width: 50px;
  height: 50px;
}
.loader {
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #3498db;
  width: 40px;
  height: 40px;
  animation: spin 0.7s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media screen and (min-width: 1400px){
  .pageContainerWidth{
    max-width: 1320px;
  }
}
@media screen and (max-width: 1199px) {
  .pageContainerWidth {
    max-width: 970px;
    width: 100%;
  }
}
@media screen and (max-width: 991px) {
  .pageContainerWidth {
    max-width: 750px;
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .pageContainerWidth {
    max-width: 100%;
    width: 100%;
    padding: 0 6%;
  }
}